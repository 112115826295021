.rv-xy-plot__grid-lines__line {
    stroke:#e6e6e9;
  }

.rv-xy-plot__axis__tick__text {
    fill: #6b6b76;
    font-size: 9px;
  }


.rv-xy-plot__series--label-text {
    text-anchor: middle;
    dominant-baseline: middle;
  }