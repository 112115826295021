.ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: #ffcc00;
    border-color: transparent;
}

.ant-progress.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #ffcc00;
    border-color: transparent;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #ffcc00;
    background-color: #ffcc00;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    color: white;
}

.ant-steps.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail::after {
    width: 2px;
}

.ant-steps.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
    padding: 30px 0 3px;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: gray;
}

/* Regla para elementos activos en estado de proceso */
.ant-steps.ant-steps-vertical > .ant-steps-item.ant-steps-item-process.ant-steps-item-active .ant-steps-item-content {
    background-color: #ffcc00;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}

.ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-content{
    padding-left: 12px;
}

.ant-steps .ant-steps-item-title {
    padding-inline-end: 0;
}

.ant-steps.ant-steps-vertical>.ant-steps-item .ant-steps-item-description {
    width: 60%;
}

.ant-steps.ant-steps-vertical>.ant-steps-item .ant-steps-item-title {
    width: 60%;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: #000073;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: #3d3dff;
    background-color: #3d3dff
}

.ant-steps.ant-steps-vertical>.ant-steps-item .ant-steps-item-title {
    line-height: 20px;
    padding-top: 12px;
}

.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: #000073;
    font-weight: bold;
}

.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: #000073;
    font-weight: bold;
}

.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: #000073;
    font-size: 12px;
}

.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: #000073;
    font-size: 12px;
}

.ant-steps .ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    font-size: 12px;
}

.ant-picker{
    border: 1px solid #000000;
}