.viewColumn {
    margin-top: 50px; 
}

@media (max-width: 992px) {
    .viewColumn {
        margin-top: 0px;
    }
}

