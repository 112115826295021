.textCard {
    font-weight: 600; 
    line-height: 1.3;
}



@media (max-width: 1440px) {
    .textCard {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

